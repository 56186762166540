import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import Button from '@material-ui/core/Button';
import { Button, Icon } from 'lib/UI';
import { IFlow, MAX_NUMBER_OF_FLOWS } from 'models/Flow.model';
import { PageLoader } from 'apps/layout';
import { useFormatMessage } from 'apps/intl';
import { useFlowListLoad } from 'apps/FlowList';
import { useMerchantPlan } from 'apps/merchant';
import { selectLanguage, selectMerchantFlowList } from 'state/merchant/merchant.selectors';
import { QATags } from 'models/QA.model';
import { clearCurrentTemplate } from 'apps/Templates';
import { flowBuilderClearStore, toggleUnsavedChanges } from 'apps/flowBuilder';
import { DateFormatTypes, dateToFormatString } from 'lib/date';
import { NavigationTypes, TrackTypes, useTrackClickEvent, useTrackNavigation } from 'apps/Tracking';
import { FlowsTable } from '../FlowsTable/FlowsTable';
import { useStyles } from './FlowList.styles';
import { Routes } from '../../../../models/Router.model';
import { merchantCreateFlow } from '../../../../state/merchant/merchant.actions';

export function FlowList() {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();
  const history = useHistory();
  const merchantFlowList = useSelector<any, IFlow[]>(selectMerchantFlowList);
  const language = useSelector(selectLanguage);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const isButtonDisabled = (merchantFlowList || []).length >= MAX_NUMBER_OF_FLOWS;
  const [open, setOpen] = useState<boolean>(isButtonDisabled && isMobile);
  const flowListModel = useFlowListLoad();
  const { isFreemium } = useMerchantPlan();

  useTrackNavigation(NavigationTypes.WorkflowList);
  const trackEvent = useTrackClickEvent();

  useEffect(() => {
    setOpen(isButtonDisabled && isMobile);
  }, [isMobile, isButtonDisabled]);

  useEffect(() => {
    dispatch(clearCurrentTemplate());
    dispatch(flowBuilderClearStore());
    dispatch(toggleUnsavedChanges(false));
  }, [dispatch]);

  const handleOpen = useCallback(() => {
    if (merchantFlowList?.length >= MAX_NUMBER_OF_FLOWS) {
      setOpen(true);
    }
  }, [merchantFlowList]);

  const handleClose = useCallback(() => {
    if (!isMobile) {
      setOpen(false);
    }
  }, [isMobile]);

  const handleBuildMetamapButtonClick = useCallback(async () => {
    try {
      const currentDate = dateToFormatString(new Date(), language, 'DD-MM-YY_H-mm-ss_SSS' as DateFormatTypes);
      const newFlow = await dispatch(merchantCreateFlow({ name: `${formatMessage('StartModal.untitled')} ${currentDate}` })) as IFlow;
      trackEvent(TrackTypes.WorkflowCreated, {
        workflowId: newFlow.id,
        workflowName: newFlow.name,
        workflowType: newFlow.integrationType,
      });
      history.push(`${Routes.flow.root}/${newFlow.id}`);
    } catch (error) {
      history.push(Routes.flow.root);
    }
  }, [language, dispatch, formatMessage, trackEvent, history]);

  const shouldShowCreateMetamapButton = !isFreemium && (merchantFlowList?.length > 0);

  if (!flowListModel.isLoaded) {
    return <PageLoader />;
  }

  return (
    <Box p="8px" height="100%">
      <Box>
        <Box display="flex" flexDirection="row" alignItems="center" gridGap={18}>
          <Typography variant="h3">{formatMessage('VerificationFlow.page.title')}</Typography>
          {shouldShowCreateMetamapButton && (
            <Tooltip
              enterTouchDelay={0}
              placement={isMobile ? 'bottom' : 'left'}
              arrow
              open={open}
              onOpen={handleOpen}
              onClose={handleClose}
              classes={{
                tooltip: classes.tooltip,
                popper: classes.tooltipPopper,
                arrow: classes.tooltipArrow,
              }}
              title={formatMessage('VerificationFlow.page.tooltip')}
            >
              <Button
                disabled={isButtonDisabled}
                onClick={handleBuildMetamapButtonClick}
                data-qa={QATags.Flows.CreateNewFlowButton}
                id="create-new-flow"
              >
                <Icon name="add" />
                {formatMessage('VerificationFlow.page.button')}
              </Button>
            </Tooltip>
          )}
        </Box>
        <Box py={{ xs: 2, lg: 0 }} className={classes.table}>
          <FlowsTable onAddNewFlow={handleBuildMetamapButtonClick} />
        </Box>
      </Box>
    </Box>
  );
}
