import { useAuth0 } from '@auth0/auth0-react';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { notification } from 'apps/ui';
import { Routes } from 'models/Router.model';
import { ErrorStatusTypes } from 'models/Error.model';
import { useFormatMessage } from 'apps/intl';
import { SubmitHandler } from 'react-hook-form';
import { selectLanguage } from 'state/merchant';
import { useSelector } from 'react-redux';
import { SupportedLocales } from 'models/Intl.model';
import { auth0CallbackUrlParams, auth0Errors, exposedErrorCodes, getAccessToken, IdentifierFirstErrorTypes, IIdentifierFirstInputs } from '../models/Auth0.model';
import { identifierFirst } from '../state/auth0.actions';

interface IUseAuth0IdentifierFirst {
  setCheckedCaptcha: (toggle: boolean) => void;
  recaptchaRef: any;
  setFormError?: (fieldName: string, errorMessage: string) => void;
  noAccessToOrganizationRedirect?: (displayName: string) => void;
}

export function useAuth0IdentifierFirst({
  setCheckedCaptcha, recaptchaRef, setFormError, noAccessToOrganizationRedirect,
}: IUseAuth0IdentifierFirst): [SubmitHandler<IIdentifierFirstInputs>, boolean] {
  const history = useHistory();
  const location = useLocation();
  const formatMessage = useFormatMessage();
  const currentLocale = useSelector<any, SupportedLocales>(selectLanguage);
  const {
    loginWithRedirect,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    const urlQueryParams = new URLSearchParams(location.search);
    const invitation = urlQueryParams.get(auth0CallbackUrlParams.invitation);
    const organization = urlQueryParams.get(auth0CallbackUrlParams.organization);
    const href = urlQueryParams.get(auth0CallbackUrlParams.href);
    if (invitation && organization) {
      loginWithRedirect({
        organization,
        invitation,
        ui_locales: currentLocale,
        login_hint: '',
        redirectUri: href || `${window.location.origin}${Routes.root}`,
      });
    }
  }, [currentLocale, location, loginWithRedirect]);

  useEffect(() => {
    const urlQueryParams = new URLSearchParams(location.search);
    const errorFromRedirect = urlQueryParams.get(auth0CallbackUrlParams.error);
    if (errorFromRedirect) {
      switch (errorFromRedirect) {
        case exposedErrorCodes[auth0Errors.accessDenied].toString(): {
          notification.error(formatMessage('Auth0.error.invitationExpired'));
          break;
        }
        case exposedErrorCodes[auth0Errors.userIsNotPartOfOrganization].toString(): {
          notification.error(formatMessage('Auth0.error.userNotPartOfOrganization'));
          break;
        }
        case exposedErrorCodes[auth0Errors.userBlocked].toString(): {
          notification.error(formatMessage('Auth0.error.userBlocked'));
          break;
        }
        case exposedErrorCodes[auth0Errors.invalidRequest].toString(): {
          notification.error(formatMessage('Auth0.error.badInvitation'));
          break;
        }
        case exposedErrorCodes[auth0Errors.passwordExpired].toString(): {
          notification.error(formatMessage('Auth0.error.passwordExpired'));
          break;
        }
        case exposedErrorCodes[auth0Errors.freemiumSignupBlockedByCountry].toString(): {
          notification.error(formatMessage('Auth0.error.freemiumSignupBlockedByCountry'));
          break;
        }
        default: {
          notification.error(formatMessage('Error.common'));
          break;
        }
      }
    }
    // deps should be empty for do not duplicate messages.
  }, []);

  useEffect(() => {
    const retriggerAuth = async () => {
      try {
        await getAccessToken(getAccessTokenSilently);
        history.push(Routes.root);
      } catch {
        history.push(Routes.auth.signIn);
      }
    };
    if (!isLoading && isAuthenticated) {
      retriggerAuth();
    }
  }, [isLoading, isAuthenticated, history, getAccessTokenSilently]);

  const handleFormSubmit: SubmitHandler<IIdentifierFirstInputs> = useCallback(async (data) => {
    try {
      const { organization } = await identifierFirst(data);
      loginWithRedirect({
        organization,
        ui_locales: currentLocale,
        login_hint: data.email,
        redirectUri: `${window.location.origin}${Routes.root}`,
      });
    } catch (error: any) {
      const status = error?.response?.status;
      const details = error?.response?.data;
      if (status === ErrorStatusTypes.TooManyRequests) {
        setCheckedCaptcha(false);
        if (recaptchaRef.current) {
          recaptchaRef.current?.reset();
        }
        notification.error(formatMessage('SignIn.form.error.tooManyRequest'));
      } else if (status === ErrorStatusTypes.WrongCredentials) {
        if (details?.code === IdentifierFirstErrorTypes.noAccessToOrganization) {
          const displayName = details?.displayName;
          setFormError('email', 'noAccessToOrganization');
          setCheckedCaptcha(false);
          noAccessToOrganizationRedirect(displayName);
        } else if (details?.code === IdentifierFirstErrorTypes.useBusinessEmail) {
          setFormError('email', 'useBusinessEmail');
          setCheckedCaptcha(false);
          notification.error(formatMessage('SignIn.form.useBusinessEmail.error'));
        } else if (details?.code === IdentifierFirstErrorTypes.checkYourEmail) {
          setCheckedCaptcha(false);
          notification.info(formatMessage('SignIn.form.noAccessToOrganization.acceptInvitation'));
        } else if (details?.code === IdentifierFirstErrorTypes.emailTooRisky) {
          notification.error(formatMessage('SignIn.form.error.emailTooRisky'));
        } else {
          notification.error(formatMessage('Error.common'));
        }
      } else {
        notification.error(formatMessage('Error.common'));
      }
    }
  }, [loginWithRedirect, currentLocale, setCheckedCaptcha, recaptchaRef, formatMessage, setFormError, noAccessToOrganizationRedirect]);

  return [handleFormSubmit, isLoading];
}
