import { Grid, Button, Box, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useOverlay } from 'apps/overlay';
import { Badge, notification, Warning, WarningTypes } from 'apps/ui';
import classnames from 'classnames';
import { LoadableAdapter } from 'lib/Loadable.adapter';
import { CustomDocumentResponse, CustomDocumentReviewStatus, MAX_CUSTOMDOC_QTY } from 'models/CustomDocument.model';
import { ErrorMessagesTokenTypes } from 'models/Error.model';
import React, { useCallback, useEffect } from 'react';
import { useFormatMessage } from 'apps/intl';
import { FiTrash2, FiEye } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { merchantCustomDocumentsLoad, merchantCreateCustomDocumentClearStats } from 'state/merchant/merchant.actions';
import { selectMerchantCustomDocumentsModel, selectMerchantModel } from 'state/merchant/merchant.selectors';
import { NOT_UNIQUE_TYPE_ERROR, CustomDocumentWizardStepTypes } from '../../models/CustomDocument.model';
import { useStyles } from './CustomDocumentList.styles';
import { deleteCustomDocument, resetCustomDocumentModal, saveCustomDocument, updateCustomDocumentModal, updateCustomDocumentWizardStep } from '../../state/customDocument.actions';
import { CustomDocumentWizard } from '../CustomDocumentWizard/CustomDocumentWizard';

function StatusBadge({ status }: {
  status: CustomDocumentReviewStatus;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  switch (status) {
    case CustomDocumentReviewStatus.Rejected:
      return (
        <Badge text={formatMessage('CustomDocuments.settings.customDocumentList.status.rejected')} className={classes.rejectedStatus} />
      );
    case CustomDocumentReviewStatus.Accepted:
      return null;
    default:
    case CustomDocumentReviewStatus.Pending:
      return (
        <Badge text={formatMessage('CustomDocuments.settings.customDocumentList.status.pending')} className={classes.pendignStatus} />
      );
  }
}

export function CustomDocumentList({ disabled = false }: {
  disabled: boolean;
}) {
  const dispatch = useDispatch();
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const [createOverlay, closeOverlay] = useOverlay();
  const merchantModel = useSelector(selectMerchantModel);
  const customDocuments = useSelector(selectMerchantCustomDocumentsModel);
  const isMaxCustomDocumentQty = customDocuments.value.length >= MAX_CUSTOMDOC_QTY;

  const handleCloseOverlay = useCallback(() => {
    dispatch(merchantCreateCustomDocumentClearStats());
    closeOverlay();
  }, [closeOverlay, dispatch]);

  const handleSaveDocument = useCallback(async (customDocumetUpdate: Partial<CustomDocumentResponse>) => {
    const newCustomDocumetUpdate = { ...customDocumetUpdate };
    newCustomDocumetUpdate.type = newCustomDocumetUpdate.type?.trim();

    await dispatch(saveCustomDocument(newCustomDocumetUpdate));
    handleCloseOverlay();
  }, [dispatch, handleCloseOverlay]);

  const handleDelete = useCallback((index: number) => async () => {
    const customDocumentType = customDocuments.value[index].type;
    try {
      await dispatch(deleteCustomDocument(customDocumentType));
    } catch (error: any) {
      notification.error(formatMessage(error?.data?.code ? `CustomDocuments.settings.error.${error.data.code}` : ErrorMessagesTokenTypes.ERROR_COMMON, { messageValues: { flowName: error?.data?.flowName } }));
    }
  }, [dispatch, formatMessage, customDocuments]);

  useEffect(() => {
    if (merchantModel.isLoaded && LoadableAdapter.isPristine(customDocuments) && !customDocuments.value?.length) {
      dispatch(merchantCustomDocumentsLoad());
    }
  }, [dispatch, customDocuments, merchantModel]);

  useEffect(() => {
    if (customDocuments.error === NOT_UNIQUE_TYPE_ERROR) {
      dispatch(updateCustomDocumentWizardStep(CustomDocumentWizardStepTypes.BasicInfo));
    }
  }, [customDocuments.error, dispatch]);

  return (
    <Box>
      <Box mt={2} className={classes.buttonWrap}>
        {isMaxCustomDocumentQty && (
          <Box mb={0.5} className={classes.warningWrap}>
            <Warning
              type={WarningTypes.Notify}
              label={formatMessage('CustomDocuments.settings.customDocumentList.help', { messageValues: { count: MAX_CUSTOMDOC_QTY } })}
              isLabelColored
              isIconExist={false}
            />
          </Box>
        )}
        <Button
          className={classnames(classes.addButton, classes.addButtonFullWidth)}
          disabled={disabled || isMaxCustomDocumentQty || !customDocuments.isLoaded}
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            dispatch(resetCustomDocumentModal());
            createOverlay(<CustomDocumentWizard onDone={handleSaveDocument} onClose={handleCloseOverlay} />, { sticky: true });
          }}
        >
          {formatMessage('CustomDocuments.settings.customDocumentList.request')}
        </Button>
        <Box mt={1}>
          <Typography variant="body1" color="textSecondary">
            {formatMessage('CustomDocuments.settings.customDocumentList.request.message')}
          </Typography>
        </Box>
      </Box>

      {!disabled && (
        <Grid item xs={12}>
          {customDocuments.isLoading ? (
            <Grid container spacing={1} direction="column">
              <Grid item>
                <Skeleton variant="rect" width="100%" height={40} />
              </Grid>
              <Grid item>
                <Skeleton variant="rect" width="100%" height={40} />
              </Grid>
            </Grid>
          ) : (
            customDocuments.value.map((customDocument: CustomDocumentResponse, index: number) => (
              <Box className={classnames(classes.tag, classes.disabledTag)} key={customDocument.type}>
                {customDocument.name}
                <Box className={classes.buttonsHolder}>
                  <StatusBadge status={customDocument.status} />
                  <Button
                    className={classes.viewButton}
                    onClick={() => {
                      dispatch(updateCustomDocumentModal(customDocument));
                      createOverlay(<CustomDocumentWizard editable={false} onDone={handleSaveDocument} onClose={handleCloseOverlay} />, { sticky: true });
                    }}
                  >
                    <FiEye />
                  </Button>
                  <Button
                    className={classes.deleteButton}
                    onClick={handleDelete(index)}
                  >
                    <FiTrash2 />
                  </Button>
                </Box>
              </Box>
            )))}
        </Grid>
      )}
    </Box>
  );
}
