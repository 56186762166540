import { http, httpVPN, httpNew } from 'lib/client/http';
import { AuthInputTypes } from 'apps/auth';
import { OAuthError } from '@auth0/auth0-react';
import { decodeToken } from 'react-jwt';

export interface IIdentifierFirstInputs {
  [AuthInputTypes.CaptchaToken]: string;
  [AuthInputTypes.Email]: string;
  [AuthInputTypes.FreemiumPageVersion]?: string;
}

export interface IIdentifierFirstResponseData {
  organization: string;
}

export enum IdentifierFirstErrorTypes {
  checkYourEmail = 'checkYourEmail',
  noAccessToOrganization = 'noAccessToOrganization',
  useBusinessEmail = 'useBusinessEmail',
  emailTooRisky = 'emailTooRisky',
}

export enum PermissionAccessTypes {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
  Export = 'export',
}

export enum PermissionsTypes {
  verifications = 'verifications',
  customers = 'customers',
  flows = 'flows',
  analytics = 'analytics',
  integrationSettings = 'integrationSettings',
  accountSettings = 'accountSettings',
  agentHistory = 'agentHistory',
  identities = 'identity',
}

export type Permissions = Record<PermissionsTypes, { accessTypes: PermissionAccessTypes[] }>;

export interface IRole {
  isOwner: boolean;
  permissions: Permissions;
  name: string;
}

export interface IAccessTypes {
  create: string;
  read: string;
  update: string;
  delete: string;
  export: string;
}

export const auth0CallbackUrlParams = {
  href: 'href',
  invitation: 'invitation',
  organization: 'organization',
  error: 'error',
  errorDescription: 'error_description',
};

export const auth0Errors = {
  accessDenied: 'access_denied',
  invalidRequest: 'invalid_request',
  unauthorized: 'unauthorized',
  userBlocked: 'userBlocked',
  userIsNotPartOfOrganization: 'userIsNotPartOfOrganization',
  passwordExpired: 'passwordExpired',
  freemiumSignupBlockedByCountry: 'freemiumSignupBlockedByCountry',
};

export const auth0ErrorDescriptions = {
  userIsBlocked: 'user is blocked',
};

export const auth0ErrorDescriptionPatterns = [
  { pattern: /is not part of the org/g, key: 403 },
  { pattern: /user is blocked/g, key: 423 },
  { pattern: /not allowed to accept the current invitation/g, key: 405 },
  { pattern: /password.expired/g, key: 402 },
  { pattern: /Users from .+ are not allowed/g, key: 451 },
];

export const auth0DatabaseUserIdPrefix = 'auth0';

export const defaultErrorCode = 401;

export const exposedErrorCodes = {
  [auth0Errors.accessDenied]: 401,
  [auth0Errors.userIsNotPartOfOrganization]: 403,
  [auth0Errors.invalidRequest]: 405,
  [auth0Errors.userBlocked]: 423,
  [auth0Errors.passwordExpired]: 402,
  [auth0Errors.freemiumSignupBlockedByCountry]: 451,
};

let userRole: Nullable<IRole> = null;
export const setRole = (role: IRole) => {
  userRole = role;
};

export const getRole = (): Nullable<IRole> => userRole;

export const getAccessToken = async (getAccessTokenSilently, retries = 0) => {
  if (retries >= 3) {
    return undefined;
  }
  try {
    const accessToken = await getAccessTokenSilently({ ignoreCache: true });
    httpVPN.setToken(accessToken);
    httpNew.setToken(accessToken);
    http.setToken(accessToken);

    const decodedToken = decodeToken<{ role: IRole; exp: number }>(accessToken);
    // AccessToken lifetime is quiet short. This hook will use refreshToken to update accessToken
    const tokenExpiresIn = decodedToken.exp * 1000 - Date.now() - 5000;
    setTimeout(() => getAccessToken(getAccessTokenSilently), tokenExpiresIn);

    return setRole(decodedToken.role);
  } catch (error: any) {
    return getAccessToken(getAccessTokenSilently, retries + 1);
  }
};

export function parseError(error: Nullable<OAuthError>): Nullable<number> {
  // SDK issue here. To get an error string need to check other.error.error.
  const auth0Error = { ...(error || {}) };
  const isNeedParseError = [auth0Errors.accessDenied, auth0Errors.unauthorized, auth0Errors.invalidRequest].includes(auth0Error?.error);

  if (isNeedParseError) {
    const matchedError = auth0ErrorDescriptionPatterns.find(({ pattern }) => !!(auth0Error?.error_description && auth0Error.error_description.match(pattern)));
    return matchedError ? matchedError.key : defaultErrorCode;
  }
  return null;
}
