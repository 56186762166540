import IconButton from '@material-ui/core/IconButton';
import { Modal } from 'apps/overlay';
import { useProduct } from 'apps/Product';
import { useMerchantPlan } from 'apps/merchant';
import { ITemplateMetadata, MetadataTypes, useLoadMetadataList } from 'apps/Templates';
import { LoadableAdapter } from 'lib/Loadable.adapter';
import { Loadable } from 'models/Loadable.model';
import { ProductIntegrationTypes } from 'models/Product.model';
import React, { useEffect, useMemo, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { ICardsOptions, IFilterOptions, initialFiltersData } from '../../model/SolutionCatalog.model';
import { loadTemplates } from '../../store/SolutionCatalog.action';
import { selectAllTemplatesListModel } from '../../store/SolutionCatalog.selectors';
import { TemplatesCatalog } from '../TemplatesCatalog/TemplatesCatalog';
import { useStyles } from './TemplatesModal.styles';

export function TemplatesModal({ handleCardClick, handleClose }: { handleCardClick: (id: string) => void; handleClose?: () => void }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  useProduct();
  const { isFreemium } = useMerchantPlan();
  const templatesListModel = useSelector<any, Loadable<ICardsOptions[]>>(selectAllTemplatesListModel);
  const filtersDataModel = useLoadMetadataList();
  const filtersDataWithoutEmpty = useMemo<ITemplateMetadata[]>(() => {
    if (!filtersDataModel.value?.length || !templatesListModel.value?.length) {
      return [];
    }
    return filtersDataModel.value.filter((filter) => filter.type === MetadataTypes.Country || templatesListModel.value.some((template) => template.metadata.some((metadata) => metadata.name === filter.name)));
  }, [filtersDataModel.value, templatesListModel.value]);
  const [currentFilters, setCurrentFilters] = useState<IFilterOptions>(initialFiltersData);
  const [filteredIntegrationType, setFilteredIntegrationType] = useState<Nullable<ProductIntegrationTypes>>(null);
  const [filteredTemplates, setFilteredTemplates] = useState<ICardsOptions[]>(templatesListModel.value || []);

  useEffect(() => {
    if (LoadableAdapter.isPristine(templatesListModel)) {
      dispatch(loadTemplates([]));
    }
  }, [dispatch, templatesListModel]);

  useEffect(() => {
    // While we have a very small amount of templates, we don't REALLY filter by country, to not to show empty responses
    const filtersArray: ITemplateMetadata[] = [...currentFilters.useCase, ...currentFilters.industry];
    const newFilteredTemplates: ICardsOptions[] = (templatesListModel.value || []).filter((cardOption) => filtersArray.every((filter) => cardOption.metadata.some((metadata) => metadata.name === filter.name)));
    if (currentFilters.country?.length) {
      newFilteredTemplates.sort((first, second) => {
        const firstEnabledCountries = currentFilters.country.reduce<number>((count, filter) => count + (first.metadata.some((metadata) => metadata.name === filter.name) ? 1 : 0), 0);
        const secondEnabledCountries = currentFilters.country.reduce<number>((count, filter) => count + (second.metadata.some((metadata) => metadata.name === filter.name) ? 1 : 0), 0);
        return secondEnabledCountries - firstEnabledCountries;
      });
    }
    if (filteredIntegrationType !== null) {
      setFilteredTemplates(newFilteredTemplates.filter((template) => template.flow.integrationType === filteredIntegrationType));
    } else {
      setFilteredTemplates(newFilteredTemplates);
    }
  }, [currentFilters, filteredIntegrationType, templatesListModel.value]);

  return (
    <Modal
      onClose={handleClose}
      className={classes.modal}
      customCloseIcon={(
        <IconButton className={classes.btnClose}>
          <FiX size={24} />
        </IconButton>
      )}
    >
      <TemplatesCatalog
        isLoading={templatesListModel.isLoading}
        currentFilters={currentFilters}
        setCurrentFilters={setCurrentFilters}
        filteredIntegrationType={filteredIntegrationType}
        setFilteredIntegrationType={setFilteredIntegrationType}
        filtersData={filtersDataWithoutEmpty}
        templates={filteredTemplates}
        onSelectTemplate={handleCardClick}
        isFreemium={isFreemium}
      />
    </Modal>
  );
}
