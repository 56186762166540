import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import { CountryAndWorldPartSelectingForm, ICountryAndWorldPart } from 'apps/countries';
import { IntegrationTypeSelectingForm } from 'apps/Integration';
import { useFormatMessage } from 'apps/intl';
import { PageLoader } from 'apps/layout';
import { ITemplateMetadata, MetadataTypes } from 'apps/Templates';
import { ProductIntegrationTypes } from 'models/Product.model';
import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import { FiChevronDown, FiFilter } from 'react-icons/fi';
import { UpgradeFreemiumButton } from 'apps/SubscriptionStatus';
import { QATags } from 'models/QA.model';
import { ReactComponent as RoundPlusIcon } from '../../assets/round-plus-icon.svg';
import { ReactComponent as RoundCrossIcon } from '../../assets/round-cross-icon.svg';
import { getFiltersOptions, getSelectedFilterName, ICardsOptions, IFilterOptions, IModifiedFiltersOptions, initialFiltersData } from '../../model/SolutionCatalog.model';
import { TemplateCard } from '../TemplateCard/TemplateCard';
import { TemplateFilterOption } from '../TemplateFilterOption/TemplateFilterOption';
import { TemplateFilters } from '../TemplateFilters/TemplateFilters';
import { useStyles } from './TemplatesCatalog.styles';

export function TemplatesCatalog({ filtersData, templates, currentFilters, setCurrentFilters, filteredIntegrationType, setFilteredIntegrationType, isFreemium, onSelectTemplate, isLoading }: {
  filtersData: ITemplateMetadata[];
  templates: ICardsOptions[];
  currentFilters: IFilterOptions;
  setCurrentFilters: (filters: IFilterOptions) => void;
  filteredIntegrationType: Nullable<ProductIntegrationTypes>;
  setFilteredIntegrationType: (integrationType: Nullable<ProductIntegrationTypes>) => void;
  onSelectTemplate: (id: string) => void;
  isLoading: boolean;
  isFreemium: boolean;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const theme = useTheme();
  const [isCountriesFilterOpen, setIsCountriesFilterOpen] = useState<boolean>(false);
  const [isIntegrationTypeFilterOpen, setIsIntegrationTypeFilterOpen] = useState<boolean>(false);
  const filtersOptions: IModifiedFiltersOptions[] = getFiltersOptions(filtersData);
  const filterByIndustryOptions: IModifiedFiltersOptions = filtersOptions.find((filterOptions) => filterOptions.title === MetadataTypes.Industry);
  const filterByUseCaseOptions: IModifiedFiltersOptions = filtersOptions.find((filterOptions) => filterOptions.title === MetadataTypes.UseCase);
  const filterByCountryOptions: IModifiedFiltersOptions = filtersOptions.find((filterOptions) => filterOptions.title === MetadataTypes.Country);
  const selectedFilterName: Nullable<string> = getSelectedFilterName(currentFilters);

  const possibleCountryFilters: ICountryAndWorldPart[] = useMemo<ICountryAndWorldPart[]>(() => {
    if (!filterByCountryOptions) {
      return [];
    }
    return filterByCountryOptions.data.filter((templateMetadata) => templateMetadata?.data?.worldPart)
      .map<ICountryAndWorldPart>((templateMetadata) => ({
        worldPart: templateMetadata?.data?.worldPart,
        countryCode: templateMetadata.name,
      }));
  }, [filterByCountryOptions]);

  const currentCountryFilters: ICountryAndWorldPart[] = useMemo<ICountryAndWorldPart[]>(() => {
    if (!currentFilters[MetadataTypes.Country]) {
      return [];
    }
    return currentFilters[MetadataTypes.Country].filter((templateMetadata) => templateMetadata?.data?.worldPart)
      .map<ICountryAndWorldPart>((templateMetadata) => ({
        worldPart: templateMetadata?.data?.worldPart,
        countryCode: templateMetadata.name,
      }));
  }, [currentFilters]);

  const handleSelectAllTemplates = () => {
    // clicking on all templates button doesn't clear filter by country
    setCurrentFilters({ ...initialFiltersData, [MetadataTypes.Country]: currentFilters[MetadataTypes.Country] });
  };

  const handleSelectFilter = (type: MetadataTypes) => (filter: ITemplateMetadata) => {
    setCurrentFilters({
      ...initialFiltersData,
      [MetadataTypes.Country]: currentFilters[MetadataTypes.Country],
      [type]: [filter],
    });
  };

  const handleOpenCountriesFilter = () => setIsCountriesFilterOpen(true);
  const handleCloseCountriesFilter = () => setIsCountriesFilterOpen(false);
  const handleOpenIntegrationTypeFilter = () => setIsIntegrationTypeFilterOpen(true);
  const handleCloseIntegrationTypeFilter = () => setIsIntegrationTypeFilterOpen(false);

  const handleSubmitIntegrationType = (newIntegrationType: Nullable<ProductIntegrationTypes>) => {
    setFilteredIntegrationType(newIntegrationType);
    handleCloseIntegrationTypeFilter();
  };

  const handleClearIntegrationType = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleSubmitIntegrationType(null);
  };

  const handleSubmitCountries = (countryAndWorldParts: ICountryAndWorldPart[]) => {
    setCurrentFilters({
      ...currentFilters,
      [MetadataTypes.Country]: filterByCountryOptions.data.filter((templateMetadata) => countryAndWorldParts.some((countryAndWorldPart) => countryAndWorldPart.worldPart === templateMetadata.data?.worldPart && countryAndWorldPart.countryCode === templateMetadata.name)),
    });
    handleCloseCountriesFilter();
  };

  const handleClearCountries = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleSubmitCountries([]);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.sidebar}>
        <Box className={classes.description}>
          <Box className={classes.modalTitle}>
            {formatMessage('TemplatesModal.title')}
          </Box>
          <Box className={classes.modalSubtitle}>
            {formatMessage('TemplatesModal.subtitle')}
          </Box>
        </Box>
        <Box mb="32px" fontSize={18} fontWeight={700} lineHeight="22px">
          <TemplateFilterOption
            text={formatMessage('TemplateFilter.label.allTemplates')}
            isChecked={selectedFilterName === null}
            onChange={handleSelectAllTemplates}
          />
        </Box>
        {filterByUseCaseOptions && (
          <TemplateFilters
            title={filterByUseCaseOptions.title}
            filterData={filterByUseCaseOptions.data}
            currentFilters={currentFilters[MetadataTypes.UseCase]}
            onSelectFilter={handleSelectFilter(MetadataTypes.UseCase)}
          />
        )}
        {filterByIndustryOptions && (
          <TemplateFilters
            title={filterByIndustryOptions.title}
            filterData={filterByIndustryOptions.data}
            currentFilters={currentFilters[MetadataTypes.Industry]}
            onSelectFilter={handleSelectFilter(MetadataTypes.Industry)}
          />
        )}
      </Box>
      <Box className={classes.content}>
        <Box className={classes.header}>
          <Box>
            <Box fontSize={36} lineHeight="44px" mb="12px" color={theme.palette.common.black90}>
              {formatMessage(`Templates.category.${selectedFilterName || 'allTemplates'}.label`)}
            </Box>
            <Box fontSize={16} lineHeight="22px" color={theme.palette.common.black90}>
              {formatMessage(`Templates.category.${selectedFilterName || 'allTemplates'}.description`)}
            </Box>
          </Box>
          <Box className={classes.headerRight}>
            <Box position="relative" className={classes.headerFilters}>
              <Box className={classes.headerFilterLabel}>
                <FiFilter size={14} />
                {formatMessage('TemplateFilter.label.headerFilters')}
              </Box>
              {/* Filter by Country */}
              {possibleCountryFilters?.length > 0 && (
                <Box>
                  <Box
                    className={classes.button}
                    onClick={handleOpenCountriesFilter}
                  >
                    {currentFilters.country.length > 0 ? <RoundCrossIcon onClick={handleClearCountries} /> : <RoundPlusIcon />}
                    {formatMessage('TemplateFilter.button.countries')}
                    {currentFilters.country.length > 0 && (
                      <Box className={classes.filterValue}>
                        {currentFilters.country.length}
                        {formatMessage('CountryModalSelect.selectedAmount')}
                        <FiChevronDown size={5} />
                      </Box>
                    )}
                  </Box>
                  {isCountriesFilterOpen && (
                    <ClickAwayListener onClickAway={handleCloseCountriesFilter}>
                      <Paper className={classnames(classes.filterOptions, classes.countriesOptions)}>
                        <CountryAndWorldPartSelectingForm
                          onSubmit={handleSubmitCountries}
                          onCancel={handleCloseCountriesFilter}
                          possibleValues={possibleCountryFilters}
                          initialValues={currentCountryFilters}
                        />
                      </Paper>
                    </ClickAwayListener>
                  )}
                </Box>
              )}
              {/* Filter by integration type */}
              <Box>
                <Box
                  className={classes.button}
                  onClick={handleOpenIntegrationTypeFilter}
                >
                  {filteredIntegrationType ? <RoundCrossIcon onClick={handleClearIntegrationType} /> : <RoundPlusIcon />}
                  {formatMessage('TemplateFilter.button.integrationType')}
                  {filteredIntegrationType && (
                    <Box className={classes.filterValue}>
                      {formatMessage(`FlowBuilder.integration.${filteredIntegrationType}`)}
                      <FiChevronDown size={5} />
                    </Box>
                  )}
                </Box>
                {isIntegrationTypeFilterOpen && (
                  <ClickAwayListener onClickAway={handleCloseIntegrationTypeFilter}>
                    <Paper className={classes.filterOptions}>
                      <IntegrationTypeSelectingForm
                        onSubmit={handleSubmitIntegrationType}
                        onCancel={handleCloseIntegrationTypeFilter}
                        value={filteredIntegrationType}
                      />
                    </Paper>
                  </ClickAwayListener>
                )}
              </Box>
            </Box>
            {isFreemium && (
              <UpgradeFreemiumButton
                text={formatMessage('TemplatesModal.freemiumCTA.button')}
                intercomMessage={formatMessage('TemplatesModal.freemiumCTA.message')}
                dataQa={QATags.TemplatesModal.UpgradeFreemiumTemplates}
              />
            )}
          </Box>
        </Box>
        {isLoading ? (
          <Box height="calc(90vh - 156px)">
            <PageLoader />
          </Box>
        ) : (
          <Box className={classes.gallery} role="list">
            {templates.map((template) => (
              !!template.meritsList.length && (
                <TemplateCard
                  key={template._id}
                  id={template._id}
                  onSelectTemplate={() => onSelectTemplate(template._id)}
                  meritsList={template.meritsList}
                  flowName={template.flow.name}
                  countryFilters={currentFilters.country}
                  metadata={template.metadata}
                />
              )
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}
