/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, VideoHTMLAttributes } from 'react';
import { MediaStatusTypes } from 'apps/media/models/Media.model';
import { ImageSkeleton } from 'apps/ui';
import { useWaitMediaDownloading } from '../../hooks/waitMediaDownloading.hook';

type VideoContainerComponentProps = {
  src?: string | Promise<string> | MediaStatusTypes;
  forwardedRef?: any;
} & VideoHTMLAttributes<HTMLVideoElement>;

function VideoContainerComponent({ src, forwardedRef, ...props }: VideoContainerComponentProps) {
  const [error, setError] = useState(false);
  const [videoUrl, isMediaLoaded] = useWaitMediaDownloading(src !== MediaStatusTypes.MediaIsLoading ? src : null);

  if (error || (src === MediaStatusTypes.MediaIsLoading) || !isMediaLoaded) {
    return (<ImageSkeleton isVideoPlaceholder className={props.className} />);
  }

  return (
    <video
      src={videoUrl}
      ref={forwardedRef}
      onError={() => setError(true)}
      {...props}
    />
  );
}

export const VideoContainer = React.forwardRef(
  (props: VideoContainerComponentProps, ref) => <VideoContainerComponent forwardedRef={ref} {...props} />,
);
