import { LoadableAdapter } from 'lib/Loadable.adapter';
import { createReducer } from 'state/store.utils';
import { types } from './FlowBuilder.action';
import { FlowBuilderActionGroups, FlowBuilderStore, SliceNameTypes } from './FlowBuilder.store';

const initialState: FlowBuilderStore = {
  [SliceNameTypes.ProductsInGraph]: LoadableAdapter.createState([]),
  [SliceNameTypes.ChangeableFlow]: LoadableAdapter.createState({}),
  [SliceNameTypes.FlowHistory]: LoadableAdapter.createState([]),
  [SliceNameTypes.HistoryEntriesCount]: 0,
  selectedId: null,
  haveUnsavedChanges: false,
  isShowIntegration: false,
  isShowCustomization: false,
};

export const flowBuilderReducer = createReducer(initialState, {
  ...LoadableAdapter.createHandlers(FlowBuilderActionGroups.ChangeableFlow, SliceNameTypes.ChangeableFlow),
  ...LoadableAdapter.createHandlers(FlowBuilderActionGroups.ProductsInGraph, SliceNameTypes.ProductsInGraph),
  ...LoadableAdapter.createActionHandlers(SliceNameTypes.FlowHistory),
  [types.PRODUCT_SELECT](state: FlowBuilderStore, { payload }) {
    return {
      ...state,
      selectedId: payload,
    };
  },
  [types.HAVE_UNSAVED_CHANGES_UPDATE](state: FlowBuilderStore, { payload }) {
    return {
      ...state,
      haveUnsavedChanges: payload,
    };
  },
  [types.SET_IS_SHOW_INTEGRATION](state: FlowBuilderStore, { payload }) {
    return {
      ...state,
      isShowIntegration: payload,
    };
  },
  [types.SET_IS_SHOW_CUSTOMIZATION](state: FlowBuilderStore, { payload }) {
    return {
      ...state,
      isShowCustomization: payload,
    };
  },
  [types.HISTORY_ENTRIES_COUNT_LOAD](state, { payload }) {
    return {
      ...state,
      [SliceNameTypes.HistoryEntriesCount]: payload,
    };
  },
});
