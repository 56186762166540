import * as api from 'lib/client/merchant';
import { notification } from 'apps/ui';
import { userLoadSuccess } from 'apps/user';
import { appcues } from 'apps/Appcues';
import { createTypesSequence } from 'state/store.utils';
import { getWebhooks } from 'state/webhooks/webhooks.actions';
import { DEFAULT_LOCALE } from 'models/Intl.model';
import { isProduction } from 'models/Environment.model';
import dayjs from 'dayjs';
import { MerchantId } from 'models/Merchant.model';
import { getSignUpVarian } from 'models/ABTests.model';
import { ErrorMessagesTokenTypes } from 'models/Error.model';
import { selectConfigurationModel, selectCurrentFlowId, selectPristineFlowIds, selectMerchantFlowsModel, selectMerchantId, selectMerchantCustomDocumentsModel } from './merchant.selectors';
import { MerchantActionGroups } from './merchant.store';
import { FormatMessage } from 'apps/intl';
import { Countries } from 'models/Country.model';
import { MerchantLink } from 'apps/Links/models/links.model';
import { CustomDocumentReviewStatus } from 'models/CustomDocument.model';

export const types: any = {
  ...createTypesSequence(MerchantActionGroups.Merchant),
  ...createTypesSequence(MerchantActionGroups.Configuration),
  ...createTypesSequence(MerchantActionGroups.App),
  ...createTypesSequence(MerchantActionGroups.CustomDocuments),
  ...createTypesSequence(MerchantActionGroups.Links),
  ...createTypesSequence(MerchantActionGroups.Flows),
  ...createTypesSequence(MerchantActionGroups.HasVerifications),
  ...createTypesSequence(MerchantActionGroups.TransferOwnership),
  ...createTypesSequence(MerchantActionGroups.GetManageRoles),
  ...createTypesSequence(MerchantActionGroups.CreateManageRoles),
  ...createTypesSequence(MerchantActionGroups.UpdateManageRoles),
  ...createTypesSequence(MerchantActionGroups.DeleteManageRoles),
  CURRENT_FLOW_UPDATE: 'CURRENT_FLOW_UPDATE',
  PRISTINE_FLOWS_UPDATE: 'PRISTINE_FLOWS_UPDATE',
  BUSINESS_NAME_UPDATE: 'BUSINESS_NAME_UPDATE',
  SETTINGS_UPDATE: 'SETTINGS_UPDATE',
  CUSTOM_DOCUMENTS_CLEAR_STATS: 'CUSTOM_DOCUMENTS_CLEAR_STATS',
  UPDATE_LINKS: 'UPDATE_LINKS',
};

// -- merchant

export const merchantLoadSuccess = (data, withDashboard = true) => (dispatch) => {
  const { configurations, ...merchant } = data;
  if (!withDashboard) {
    delete configurations.dashboard;
  }

  if (merchant?.indexFields?.ownerEmail) {
    const countryCode = Array.isArray(merchant.countryCode) ? merchant.countryCode[0] : merchant.countryCode;
    const country = countryCode ? Countries.find(({ code }) => code === countryCode)?.name : undefined;

    appcues.start(merchant.indexFields.ownerEmail, {
      industryName: merchant.industryName,
      countryCode,
      country,
      createdAt: merchant.createdAt,
      updatedAt: merchant.updatedAt,
      subscriptionStatus: merchant.subscriptionStatus?.value,
      quotaVerificationsStarted: merchant.quota?.verifications?.started,
      quotaVerificationsTotal: merchant.quota?.verifications?.total,
      quotaVerificationsUsed: merchant.quota?.verifications?.used,
      userFirstName: merchant.user?.firstName,
      userLastName: merchant.user?.lastName,
      language: merchant.user?.locale || DEFAULT_LOCALE,
      merchantId: merchant.id,
      devEnvironment: !isProduction,
      signUpVariant: getSignUpVarian(),
    });
  }

  dispatch({ type: types.MERCHANT_SUCCESS, payload: merchant });
  dispatch({ type: types.CONFIGURATION_SUCCESS, payload: configurations });
  dayjs.locale(configurations?.dashboard?.language?.toLowerCase() || DEFAULT_LOCALE);
};

export const merchantClear = () => ({ type: types.MERCHANT_CLEAR, payload: {} });

export const merchantLoad = () => async (dispatch) => {
  dispatch({ type: types.MERCHANT_REQUEST });
  dispatch({ type: types.CONFIGURATION_REQUEST });
  try {
    const { data } = await api.getMerchant();
    await dispatch(merchantLoadSuccess(data));
    await dispatch(userLoadSuccess(data.user));
  } catch (error) {
    dispatch({ type: types.MERCHANT_FAILURE, error });
    dispatch({ type: types.CONFIGURATION_FAILURE, error });
    throw error;
  }
};

// -- app

export const appLoad = () => async (dispatch) => {
  dispatch({ type: types.APP_REQUEST });
  try {
    const { data } = await api.getMerchantApps();
    dispatch({ type: types.APP_SUCCESS, payload: data.apps });
    dispatch(getWebhooks());
  } catch (error) {
    dispatch({ type: types.APP_FAILURE, error });
    throw error;
  }
};

// -- configuration

export const configurationUpdate = (cfg, isSync) => async (dispatch, getState) => {
  const cfgModel = selectConfigurationModel(getState());
  const newConfiguration = {
    ...cfgModel.value,
    ...cfg,
  };

  if (!isSync) {
    dispatch({ type: types.CONFIGURATION_UPDATING, payload: newConfiguration });
    return;
  }

  if (!cfgModel.isLoaded) {
    throw Error('configuration didn\'t loaded');
  }

  dispatch({ type: types.CONFIGURATION_UPDATING, payload: newConfiguration });

  try {
    const { data } = await api.saveConfiguration(cfg);
    dispatch({ type: types.CONFIGURATION_SUCCESS, payload: data.configurations });
  } catch (error) {
    dispatch({ type: types.CONFIGURATION_FAILURE, error });
    throw error;
  }
};

export const dashboardUpdate = (data, isSync: boolean = false) => async (dispatch) => {
  await dispatch(configurationUpdate({ dashboard: { ...data } }, isSync));
};

export const changeLanguage = (language, isSync) => (dispatch) => {
  dispatch(dashboardUpdate({ language }, isSync));
  dayjs.locale(language.toLowerCase());
};

export const merchantCustomDocumentsLoad = () => async (dispatch, getState) => {
  dispatch({ type: types.CUSTOM_DOCUMENTS_REQUEST });
  const merchantId = selectMerchantId(getState());
  try {
    const { data } = await api.getMerchantCustomDocuments(merchantId);
    dispatch({
      type: types.CUSTOM_DOCUMENTS_SUCCESS,
      payload: data.map((customDoc) => ({
        ...customDoc,
        status: customDoc.status ?? CustomDocumentReviewStatus.Accepted,
      })),
    });
  } catch (error) {
    dispatch({ type: types.CUSTOM_DOCUMENTS_FAILURE, error });
    throw error;
  }
};

export const merchantCreateCustomDocument = (payload) => async (dispatch, getState) => {
  dispatch({ type: types.CUSTOM_DOCUMENTS_REQUEST });
  try {
    const merchantId = selectMerchantId(getState());
    await api.createMerchantCustomDocument(merchantId, payload);
    dispatch({ type: types.CUSTOM_DOCUMENTS_CLEAR, payload: [] });
  } catch (error: any) {
    dispatch({ type: types.CUSTOM_DOCUMENTS_FAILURE, error: error?.response?.data?.type });
    throw error;
  }
};

export const merchantCreateCustomDocumentClearStats = () => (dispatch) => {
  dispatch({ type: types.CUSTOM_DOCUMENTS_CLEAR_STATS });
};

export const merchantDeleteCustomDocument = (type) => async (dispatch, getState) => {
  try {
    const merchantId = selectMerchantId(getState());
    const { value } = selectMerchantCustomDocumentsModel(getState());
    const index = value.findIndex((customDocument) => customDocument.type === type);
    await api.deleteCustomDocument(merchantId, type);
    const payload = value.filter((_, i) => i !== index);
    dispatch({ type: types.CUSTOM_DOCUMENTS_SUCCESS, payload, isReset: true });
  } catch (error) {
    dispatch({ type: types.CUSTOM_DOCUMENTS_FAILURE, error });
    throw error;
  }
};

export const merchantUpdateCustomDocument = (type, payload) => async (dispatch, getState) => {
  const state = getState();
  dispatch({ type: types.CUSTOM_DOCUMENTS_UPDATING });
  try {
    const merchantId = selectMerchantId(state);
    const { data } = await api.updateCustomDocument(merchantId, type, payload);
    dispatch({ type: types.CUSTOM_DOCUMENTS_SUCCESS, payload: data, isReset: true });
  } catch (error) {
    dispatch({ type: types.CUSTOM_DOCUMENTS_FAILURE, error });
    throw error;
  }
};

// flows

export const updateCurrentFlowId = (data) => (dispatch) => {
  dispatch({ type: types.CURRENT_FLOW_UPDATE, payload: data });
  dispatch(getWebhooks());
};

export const setFlowPristineState = (flowId: string, isPristine: boolean) => (dispatch, getState) => {
  const flows = selectPristineFlowIds(getState());
  if (isPristine && !flows.includes(flowId)) {
    dispatch({ type: types.PRISTINE_FLOWS_UPDATE, payload: [...flows, flowId] });
  } else {
    dispatch({ type: types.PRISTINE_FLOWS_UPDATE, payload: flows.filter((id) => id !== flowId) });
  }
};

export const merchantFlowsLoad = (asMerchantId) => async (dispatch, getState) => {
  dispatch({ type: types.FLOWS_REQUEST });
  const merchantId = selectMerchantId(getState());
  try {
    const { data } = await api.getMerchantFlows(merchantId, { ...(asMerchantId && { asMerchantId }) });
    if (Array.isArray(data) && data.length > 0 && data[0].id) {
      dispatch(updateCurrentFlowId(data[0].id));
      dispatch({ type: types.FLOWS_SUCCESS, payload: data });
    } else {
      const error = new Error('Wrong data received from server');
      dispatch({ type: types.FLOWS_FAILURE, error });
      throw error;
    }
  } catch (error) {
    dispatch({ type: types.FLOWS_FAILURE, error });
    throw error;
  }
};

export const merchantUpdateFlowList = (flowId, newFlow) => (dispatch, getState) => {
  const state = getState();
  dispatch({ type: types.FLOWS_UPDATING });
  try {
    const { value } = selectMerchantFlowsModel(state);
    const index = value.findIndex((flow) => flow.id === flowId);
    const newFlowList = [...value];
    newFlowList[index] = newFlow;
    dispatch({ type: types.FLOWS_SUCCESS, payload: newFlowList, isReset: true });
  } catch (error) {
    dispatch({ type: types.FLOWS_FAILURE, error });
    throw error;
  }
};

export const merchantUpdateFlow = (payload) => async (dispatch, getState) => {
  const state = getState();
  const flowId = selectCurrentFlowId(state);
  try {
    const merchantId = selectMerchantId(state);
    const { data } = await api.updateMerchantFlow(merchantId, flowId, payload);
    dispatch(merchantUpdateFlowList(flowId, data));
  } catch (error) {
    dispatch({ type: types.FLOWS_FAILURE, error });
    throw error;
  }
};

export const merchantCreateFlow = (payload) => async (dispatch, getState) => {
  dispatch({ type: types.FLOWS_REQUEST });
  try {
    const merchantId = selectMerchantId(getState());
    const { value } = selectMerchantFlowsModel(getState());
    const { data } = await api.createMerchantFlow(merchantId, payload);
    dispatch({ type: types.FLOWS_SUCCESS, payload: [...value, data], isReset: true });
    dispatch(setFlowPristineState(data.id, true));
    return data;
  } catch (error) {
    dispatch({ type: types.FLOWS_FAILURE, error });
    throw error;
  }
};

export const merchantDeleteFlow = (id) => async (dispatch, getState) => {
  dispatch({ type: types.FLOWS_REQUEST });
  try {
    const merchantId = selectMerchantId(getState());
    const { value } = selectMerchantFlowsModel(getState());
    const index = value.findIndex((flow) => flow.id === id);
    await api.deleteMerchantFlow(merchantId, id);
    const payload = value.filter((_, i) => i !== index);
    dispatch({ type: types.FLOWS_SUCCESS, payload, isReset: true });
  } catch (error) {
    dispatch({ type: types.FLOWS_FAILURE, error });
    throw error;
  }
};

// flow update

export const merchantUpdateBusinessName = (businessName) => async (dispatch) => {
  const { data } = await api.saveBusinessName(businessName);
  dispatch({ type: types.BUSINESS_NAME_UPDATE, payload: { businessName: data.businessName } });
};

export const merchantUpdateSettings = (settings) => async (dispatch) => {
  const { data } = await api.saveSettings(settings);
  dispatch({ type: types.SETTINGS_UPDATE, payload: data.settings });
};

export const merchantUpdateAgentNotesConfig = (config) => (dispatch) => {
  dispatch(merchantUpdateSettings({ agentNotesConfig: config }));
};

export const merchantResetFlowListLoaded = () => (dispatch, getState) => {
  const { value } = selectMerchantFlowsModel(getState());
  dispatch({ type: types.FLOWS_SUCCESS, payload: value });
};

export const getVerificationSummary = () => async (dispatch) => {
  dispatch({ type: types.HAS_VERIFICATIONS_REQUEST });
  try {
    const { data } = await api.getVerificationsSummary();
    dispatch({ type: types.HAS_VERIFICATIONS_SUCCESS, payload: { hasVerifications: data?.hasVerifications } });
  } catch (error) {
    dispatch({ type: types.HAS_VERIFICATIONS_FAILURE, error });
  }
};

export const collaboratorTransferOwnership = (merchantId: MerchantId, email: string, successMsg: string) => async (dispatch) => {
  dispatch({ type: types.TRANSFER_OWNERSHIP_REQUEST });
  try {
    const { data } = await api.transferOwnership(merchantId);
    dispatch({ type: types.TRANSFER_OWNERSHIP_SUCCESS, payload: { ...data } });
    notification.success(successMsg);
  } catch (error) {
    dispatch({ type: types.TRANSFER_OWNERSHIP_FAILURE, error });
    notification.errorFormatMessage(ErrorMessagesTokenTypes.ERROR_COMMON);
  }
};

export const getCustomRoles = (showDeletedRoles : Boolean = false) => async (dispatch) => {
  dispatch({ type: types.GET_MANAGE_ROLES_REQUEST });
  try {
    const { data } = await api.getCustomRoles({
      showDeletedRoles,
    });
    dispatch({ type: types.GET_MANAGE_ROLES_SUCCESS, payload: { roles: data } });
  } catch (error) {
    dispatch({ type: types.GET_MANAGE_ROLES_FAILURE, error });
  }
};

export const createCustomRoles = (formatMessage: FormatMessage, payload, callback?: (error?: any) => void) => async (dispatch) => {
  dispatch({ type: types.CREATE_MANAGE_ROLES_REQUEST });
  try {
    const { data } = await api.createCustomRoles(payload);
    dispatch({ type: types.CREATE_MANAGE_ROLES_SUCCESS, payload: { key: 'create', newRole: data } });
    notification.info(formatMessage('settings.roles.table.createCustomRole.success.message'));
    if (callback) {
      callback();
    }
  } catch (error: any) {
    dispatch({ type: types.CREATE_MANAGE_ROLES_FAILURE, error });
    notification.error(error?.response?.data?.message || formatMessage('Error.common'));
    if (callback) {
      callback(error);
    }
  }
};

export const deleteCustomRoles = (formatMessage: FormatMessage, roleId: string) => async (dispatch) => {
  dispatch({ type: types.DELETE_MANAGE_ROLES_REQUEST });
  try {
    await api.deleteCustomRoles(roleId);
    dispatch({ type: types.DELETE_MANAGE_ROLES_SUCCESS, payload: { key: 'delete', deletedRole: { _id: roleId } } });
    notification.info(formatMessage('settings.roles.table.deleteCustomRole.success.message'));
  } catch (error: any) {
    dispatch({ type: types.DELETE_MANAGE_ROLES_FAILURE, error, message: error?.response?.data?.message });
    notification.error(error?.response?.data?.message || formatMessage('Error.common'));
  }
};

export const updateCustomRoles = (formatMessage: FormatMessage, payload, callback?: (error?: any) => void) => async (dispatch) => {
  dispatch({ type: types.UPDATE_MANAGE_ROLES_REQUEST });
  try {
    const { data } = await api.updateCustomRoles(payload);
    dispatch({ type: types.UPDATE_MANAGE_ROLES_SUCCESS, payload: { key: 'update', updatedRole: data } });
    notification.info(formatMessage('settings.roles.table.updateCustomRole.success.message'));
    if (callback) {
      callback();
    }
  } catch (error: any) {
    dispatch({ type: types.UPDATE_MANAGE_ROLES_FAILURE, error });
    notification.error(error?.response?.data?.message || formatMessage('Error.common'));
    if (callback) {
      callback(error);
    }
  }
};

export const merchantLinksLoad = () => async (dispatch, getState) => {
  const merchantId = selectMerchantId(getState());
  const { data } = await api.getLinks(merchantId);
  dispatch({ type: types.UPDATE_LINKS, payload: data });
};

export const merchantLinksUpdate = (link: MerchantLink) => async (dispatch, getState) => {
  const merchantId = selectMerchantId(getState());
  const { data } = await api.updateLink(merchantId, link);
  dispatch({ type: types.UPDATE_LINKS, payload: data });
};

export const merchantLinksCreate = (link: MerchantLink) => async (dispatch, getState) => {
  const merchantId = selectMerchantId(getState());
  const { data } = await api.createLink(merchantId, link);
  dispatch({ type: types.UPDATE_LINKS, payload: data });
};

export const merchantLinksDelete = (link: MerchantLink) => async (dispatch, getState) => {
  const merchantId = selectMerchantId(getState());
  const { data } = await api.deleteLink(merchantId, link);
  dispatch({ type: types.UPDATE_LINKS, payload: data });
};
